import React, {useState, useEffect} from 'react'
import './header.css'
import logo from '@/images/logoAndTitle.svg'
import mobileLogo from '@/images/mobileLogo.svg'
import notExpandedIcon from '@/images/notExpandedToggle.svg'
import expandedIcon from '@/images/expandedToggle.svg'
import {Link, useLocation} from 'react-router-dom'
import {BREAK_POINT} from '@/config/constant';
const NAV_LIST = [
    {
        title:'首页',
        link:'/home'
    },
    // {
    //     title:'隐私政策',
    //     link:'/privacy'
    // },
    {
        title:'商标声明',
        link:'/statement'
    },
    {
        title:'关于随鹿',
        link:'/about'
    },
    {
        title:'联系我们',
        link:'/contact'
    },
]
export default function AtHeader(){
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    const [isMobileView, setIsMobileView] = useState(isMobile||window.innerWidth <= BREAK_POINT);
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= BREAK_POINT);
        };

        // 监听窗口大小变化
        window.addEventListener('resize', handleResize);

        // 组件卸载时移除监听器
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    if(isMobileView){
        return <HeaderMobile />
    }else{
        return <HeaderPC />
    }
}
const HeaderPC = () => {
    const location = useLocation();
    let rightOptions = []
    NAV_LIST.forEach((e, k)=>{
        const select = location.pathname === e.link;
        rightOptions.push(
            <Link className='link' to={e.link} key={k}>
                <div className={select?'selected-item':'item'}>
                    <span className='linkText'>
                        {e.title}
                    </span>
                    <div className={select?'selected-line':'selected-line hidden'}/>
                </div>
            </Link>
        );
    });
    return (
        <header className='navLayout'>
            <div className='navContent' >
                <div className='navLeft'>
                    <img src={logo} alt="logo" className='logo'></img>  
                </div>
                <div className='navRight'>
                    {rightOptions}
                </div>
            </div>
        </header>
    )
}

const HeaderMobile = () => {
    const location = useLocation();
    const [toggleIcon, setToggleIcon] = useState(notExpandedIcon);
    const [showList, setShowList] = useState(false);
    // useEffect(()=>{
    //     const mobileList = document.querySelector('#mobile-list-container');
    //     console.log(mobileList)
    //     const config = {attributes: true, attributeFilter: ['style'], childList:true, subtree:true};
    //     const callback = function(mutationsList, observer) {
    //         console.log('callback');
    //         // Use traditional 'for loops' for IE 11
    //         for(let mutation of mutationsList) {
    //             console.log(mutation);
    //             if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
    //                 const displayValue = window.getComputedStyle(mobileList).display;
    //                 console.log(mutation);
    //             }
    //         }
    //     }
    //     const observer = new MutationObserver(callback);
    //     observer.observe(mobileList, config);
    //     return ()=>observer.disconnect();
    // });
    useEffect(()=>{
        const body = document.querySelector('html');
        const hideList = ()=>{
            if(!showList) return;
            setShowList(false);
            const mobileListContainer = document.querySelector('#mobile-list-container');
            const mobileToggleIcon = mobileListContainer.previousElementSibling.firstChild.firstChild;
            mobileListContainer.style.display = 'none';
            mobileToggleIcon.src = '/images/notExpandedToggle.svg';
        }
        body.addEventListener('mousewheel', hideList);
        // body.addEventListener('click', hideList);
        return ()=>{
            body.removeEventListener('mousewheel', ()=>{});
        };
    });
    return (
        <header className='mobile-nav-layout'>
           <div className='mobile-nav-content' >
                <div className='mobile-nav-left'>
                    <img src={mobileLogo} alt="logo" className='mobile-logo'></img>  
                </div>
                <div className='mobile-nav-right'
                    onClick={()=>{
                        if(showList){
                            const mobileListContainer = document.querySelector('#mobile-list-container');
                            const mobileToggleIcon = mobileListContainer.previousElementSibling.firstChild.firstChild;
                            mobileListContainer.style.display = 'none';
                            mobileToggleIcon.src = '/images/notExpandedToggle.svg';
                        }else{
                            const mobileListContainer = document.querySelector('#mobile-list-container');
                            const mobileToggleIcon = mobileListContainer.previousElementSibling.firstChild.firstChild;
                            mobileListContainer.style.display = 'block';
                            mobileToggleIcon.src = '/images/expandedToggle.svg';
                        }
                        setShowList(!showList);
                    }}
                >
                    <div className='mobile-toggle-container'>
                        <img src={toggleIcon} alt='三' className='mobile-toggle-icon'/>
                    </div>
                </div>
                <div id="mobile-list-container" className='mobile-list-container'
                    // onMouseEnter={()=>{
                    //     const mobileListContainer = document.querySelector('#mobile-list-container');
                    //     const mobileToggleIcon = mobileListContainer.previousElementSibling.firstChild.firstChild;
                    //     mobileToggleIcon.src = '/images/expandedToggle.svg';
                    // }}
                    // onMouseLeave={()=>{
                    //     const mobileListContainer = document.querySelector('#mobile-list-container');
                    //     const mobileToggleIcon = mobileListContainer.previousElementSibling.firstChild.firstChild;
                    //     mobileToggleIcon.src = '/images/notExpandedToggle.svg';
                    // }}
                >
                    <ul className='mobile-list'>
                        {
                            NAV_LIST.map((e, k)=>{
                                return (
                                    <li key={k} className='mobile-list-item'>
                                        <Link className='link' to={e.link} key={k}>
                                            <div className='mobile-item'>
                                                <span className='mobile-link-text'>
                                                    {e.title}
                                                </span>
                                            </div>
                                        </Link>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div> 
        </header>
    );
}