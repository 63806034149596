import React, { Fragment } from 'react'
import './contact.css'
import AtHeader from '@/components/header/Header'
import AtFooter from '@/components/footer/Footer'
export default function Contact(){
    const title = '联系我们'
    const items1 = [
        {
            id: 1,
            label: '客服邮箱',
            content: 'cs@soroute.cn'
        },
        {
            id: 2,
            label: '投诉邮箱',
            content: 'uf@soroute.cn'
        },
        
        

    ]
    const items2 = [
        {
            id: 3,
            label: '品牌及广告商务',
            content: 'adv@soroute.cn'
        },
        {
            id: 4,
            label: '旅行产品商城商务',
            content: 'tp@soroute.cn'
        },
        {
            id: 5,
            label: '酒店及OTA合作',
            content: 'ota@soroute.cn'
        },
    ]
    const items3 = [
        {
            id: 6,
            label: '加入随鹿',
            content: 'hr@soroute.cn'
        },
        {
            id: 7,
            label: '创建新商户（旅行产品、酒店、景点、本地服务）',
            content: '敬请期待'
        },
        // {
        //     id: 7,
        //     label: '杭州途图智能科技有限公司',
        //     content: '杭州市西湖区西溪路525号A楼东区221室'
        // },
    ]
    return (
        <section className='contact-layout-with-footer'>
            <section className='contactLayout'>
                <AtHeader/>
                <article className='contactContentLayout'>
                    <h1 className='contactTitle'>{title}</h1>
                    <div className='part1'>
                        {
                            items1.map(v=>{
                                return (
                                    <p key={v.id} className="contactItem">
                                        <span className='contactItemTitle'>{v.label}</span>
                                        <span className='contactItemContent'>{v.content}</span>
                                    </p>
                                )
                            })
                        }
                    </div>
                    <div className='part2'>
                        {
                            items2.map(v=>{
                                return (
                                    <p key={v.id} className="contactItem">
                                        <span className='contactItemTitle'>{v.label}</span>
                                        <span className='contactItemContent'>{v.content}</span>
                                    </p>
                                )
                            })
                        }
                    </div>
                    <div className='part3'>
                        {
                            items3.map(v=>{
                                return (
                                    <p key={v.id} className="contactItem">
                                        <span className='contactItemTitle'>{v.label}</span>
                                        <span className='contactItemContent'>{v.content}</span>
                                    </p>
                                )
                            })
                        }
                    </div>
                </article>
                {/* <div className='bubble1'/>
                <div className='bubble2'/>
                <div className='bubble3'/> */}
            </section>
            <AtFooter/>
        </section>
    )
}