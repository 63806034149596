import React, {useEffect, useState} from 'react';
import './footer.css';
import icon from '@/images/grayLogo.svg';
import wangan from '@/images/wangan.png';
import {BREAK_POINT} from '@/config/constant';
export default function AtFooter(){
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    const [isMobileView, setIsMobileView] = useState(isMobile||window.innerWidth <= BREAK_POINT);
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= BREAK_POINT);
        };

        // 监听窗口大小变化
        window.addEventListener('resize', handleResize);

        // 组件卸载时移除监听器
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    if(isMobileView){
        return <FooterMobile />
    }else{
        return <FooterPC />
    }
}
const FooterPC = ()=>{
    return (
        <footer className='footerLayout'>
            <div className='footerContentLayout'>
                <div className='footerLeft'>
                    <div>
                        <img src={icon} alt="icon" className='footerLeftIcon'/>
                    </div>
                    <div className='footerLeftText'>
                        <p className='firstlineContainer'>
                            &copy; 2023 soroute.cn 
                            <a className='custom-link' target='_blank' href="https://beian.miit.gov.cn">【浙ICP备2023010436号】</a>
                            <img src={wangan} alt="" className='wanganImg' />
                            <a className='custom-link' href= "https://beian.mps.gov.cn/#/query/webSearch?code=33010602013386" rel="noreferrer" target="_blank">浙公网安备33010602013386号</a >
                        </p>
                        {/* <p>网络出版服务许可证 增值电信业务经营许可证 营业执照 广播电视节目制作经营许可证 网络文化许可证</p> */}
                        <p>网上有害信息举报专区: &nbsp;<a className='custom-link' target='_blank' href="https://www.12377.cn">https://www.12377.cn</a>   &nbsp;&nbsp;全国旅游投诉电话: 12345</p>
                        <p>杭州途图智能科技有限公司</p>
                    </div>
                </div>
                <div className='footerRight'>
                    <p>随鹿SoRoute客服：cs@soroute.cn</p>
                    <p className='complaint-mail'>投诉邮箱：uf@soroute.cn</p>
                </div>
            </div>
        </footer>
    )
}
const FooterMobile = () => {
    return (
        <footer className='mobile-footer-layout'>
            <div className='mobile-footer-content-layout'>
                <div className='footer-icon-container'>
                    <img src={icon} alt="icon" className='footer-icon'/>
                </div>
                <div className='mobile-footer-second-part'>
                    <p className='custom-link'>
                        &copy; 2023 soroute.cn 
                    </p>
                    <p>
                        <a className='custom-link' target='_blank' href="https://beian.miit.gov.cn">浙ICP备2023010436号</a>
                    </p>
                    <p className='wangan-container'>
                        <img src={wangan} alt="" className='wanganImg' />
                        <a className='custom-link' href= "https://beian.mps.gov.cn/#/query/webSearch?code=33010602013386" rel="noreferrer" target="_blank">浙公网安备33010602013386号</a >
                    </p>
                    {/* <p>网络出版服务许可证 增值电信业务经营许可证 营业执照 广播电视节目制作经营许可证 网络文化许可证</p> */}
                    <p>网上有害信息举报专区: &nbsp;<a className='custom-link' target='_blank' href="https://www.12377.cn">https://www.12377.cn</a></p>
                    <p>全国旅游投诉电话: 12345</p>
                    <p>杭州途图智能科技有限公司</p>
                </div>
                <div className='mobile-footer-third-part'>
                    <p>随鹿SoRoute客服：cs@soroute.cn</p>
                    <p className='complaint-mail'>投诉邮箱：uf@soroute.cn</p>
                </div>
            </div>
        </footer>
    )
}
