import React from 'react';
import Home from './pages/home/Home'
import Privacy from './pages/privacy/Privacy';
import Statement from './pages/statement/Statement';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import AtFooter from './components/footer/Footer';
import Wait from './pages/wait/Wait';
import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";
// import './app.css';

function App() {
  return (
      <Router>
          <Routes>
              <Route exact path='/' element={<Home></Home>}/>
              <Route exact path='/home' element={<Home></Home>}/>
              {/* <Route exact path='/privacy' element={<Privacy></Privacy>}/> */}
              <Route exact path='/statement' element={<Statement></Statement>}/>
              <Route exact path='/about' element={<About></About>}/>
              <Route exact path='/contact' element={<Contact></Contact>}/>
              {/* <Route exact path='/home' element={<Home></Home>}/>
              <Route exact path='/privacy' element={<Wait></Wait>}/>
              <Route exact path='/statement' element={<Wait></Wait>}/>
              <Route exact path='/about' element={<Wait></Wait>}/>
              <Route exact path='/contact' element={<Wait></Wait>}/> */}
          </Routes>
          
      </Router>
  );
}

export default App;
