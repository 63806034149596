import React from 'react'
import './about.css'
import AtHeader from '@/components/header/Header'
import aboutTitle from '@/images/aboutTitle.svg'
import AtFooter from '@/components/footer/Footer'
import aboutBg from '@/images/aboutBg.svg'
export default function About(){
    const title='关于随鹿'
    const content=`旅行，是一种遇见新鲜与美好的生活方式。
    鹿，象征着吉祥与幸运，也代表着自然与自由。
    我们希望你能跟随鹿的脚步，遇见幸运、自由与美好！
    AI赋予了我们轻松遇见幸运的力量，我们希望将这份幸运与力量传递给屏幕前的你，与我们一起随心而动，随鹿旅行。`
    // 上线后
    // const content = `杭州途图智能科技有限公司，致力于以人工智能技术引领下一代自助游新范式。

    // 途图智能推出面向自助游场景的旅游工具软件“随鹿SoRoute，”，核心在于通过用户画像分析和社交媒体数据结构化处理，为用户提供自动、完善、高效的旅游日程定制，让你“完全被安排”
    
    // ——无需过分查阅令人眼花缭乱的旅行攻略，轻松选择偏好即可拥有完美出游计划。
    
    // ——还有准备清单、协作编排日程、旅行日志一键生成等轻量功能。
    
    // ——更有超级爽的AGI行程规划功能。`
    return (
        <section className='about-layout-with-footer'>
            <section className='aboutLayout'>
                <AtHeader/>
                <article className='aboutContentLayout'>
                    {/* <h1 className='aboutTitle'>{title}</h1> */}
                    <div className='about-content-bg-container'>
                        <img src={aboutBg} className='about-content-bg'/>
                    </div>
                    <div className='aboutContent'>
                        <div className='aboutTitle'>
                            <img src={aboutTitle} alt="关于随鹿" className='logoTitle'></img>  
                        </div>
                        <p className='aboutTextContent'>{content}</p>
                    </div>
                </article>
                {/* <div className='bubble1'/>
                <div className='bubble2'/>
                <div className='bubble3'/> */}
            </section>
            <AtFooter/>
        </section>
    )
}