import React from 'react'
import './statement.css'
import AtHeader from '@/components/header/Header'
import slLogo from '@/images/slLogo.svg'
import AtFooter from '@/components/footer/Footer'
export default function Statement(){
    return (
        <section className='statement-layout-with-footer'>
            <section className='statementLayout'>
                <AtHeader/>
                <div className='statementContentLayout'>
                    <h1 className='statementTitle'>
                        <span className='nowrap-text'>商标</span>
                        <span className='nowrap-text'>声明</span>
                    </h1>
                    <p className='statementTextContent'>
                        “随鹿”/“SoRoute”（文字）、<img src={slLogo} alt='随鹿logo' className='slLogo'></img>（图形）等商标、标识、组合商标为本公司主体之注册商标，受法律保护，侵权必究。
                        未经本公司或商标权人书面许可，任何单位及个人不得以任何方式或理由对上述商标的全部或任何部分以使用、复制、修改、传播、抄录等任何方式侵权，亦不得与其它产品捆绑使用销售。
                        凡侵犯本公司商标权的，本公司必依法追究其法律责任。
                        特此郑重法律声明！
                    </p>
                </div>
                {/* <div className='bubble1'/>
                <div className='bubble2'/>
                <div className='bubble3'/> */}
            </section>
            <AtFooter/>
        </section>
    )
}