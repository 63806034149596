import React, {useEffect, useState} from 'react'
import './home.css'
import phoneSvg from '@/images/phone.png'
import titleSvg from '@/images/homeTitle2.svg';
import androidSvg from '@/images/android.svg';
import appleSvg from '@/images/apple.svg';
import bg from '@/images/bg.svg';
import AtHeader from '@/components/header/Header';
import AtFooter from '@/components/footer/Footer';
import { BREAK_POINT } from '@/config/constant';
export default function Home(){
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    const [isMobileView, setIsMobileView] = useState(isMobile||window.innerWidth <= BREAK_POINT);
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= BREAK_POINT);
        };

        // 监听窗口大小变化
        window.addEventListener('resize', handleResize);

        // 组件卸载时移除监听器
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    if(isMobileView){
        return <HomeMobile />
    }else{
        return <HomePC />
    }
}

const HomePC = ()=>{
    const title = '随鹿旅行';
    const desc = '致力于应用AI打造完美用户体验的\n掌上自由行工具';
    const downloadApk = ()=>{
        const eleLink = document.createElement('a');
        eleLink.style.display = 'none';
        // eleLink.target = "_blank"
        eleLink.href = 'http://39.105.52.11:6001/api/dist/android?version=0.0.1';
        // eleLink.href = record;
        document.body.appendChild(eleLink);
        eleLink.click();
        document.body.removeChild(eleLink);
    }
    return (
        <section className='home-layout-with-footer'>
            <section className='homeLayout'>
                <AtHeader/>
                <article className='homeContent'>
                    <div className='home-content-bg-container'>
                        <img src={bg} className='home-content-bg'/>
                    </div>
                    <div>
                    <div className='homeLeft'>
                        <img src={phoneSvg} alt="app preview" className='preview'></img>
                    </div>
                    <div className='homeRight'>
                        <div className='homeTitleAndDesc'>
                            <div className='homeTitle'>
                                <img src={titleSvg} alt="随鹿旅行 SOROUTE"/>    
                            </div>
                            <p className='homeDesc'>致力于应用AI打造完美用户体验的<br/>掌上自由行工具</p>
                        </div>
                        <div className='buttons'>
                            <a href='https://apps.apple.com/app/随鹿旅行/id6479046744?ppid=7d7acaa3-7fdd-46b4-9549-37c1965ba0e3' className='homeLink ios' >
                                <img src={appleSvg} alt="ios icon" className='homeAppleIcon'/>
                                <span className='homeButtonText'>
                                    ios版
                                </span>
                            </a>
                            <a href='https://a.app.qq.com/o/simple.jsp?pkgname=soroute.mobile' className='homeLink android'>
                                <img src={androidSvg} alt="android icon" className='homeAndroidIcon'/>
                                <span className='homeButtonText'>
                                    Android版
                                </span>
                            </a>
                            {/* <button className='home-button'
                                onClick={()=>{
                                    window.location.href = 'https://survey.soroute.cn/s/WGxqhd';
                                }}
                            >
                                <span className='home-button-text'>申请内测资格</span>
                            </button> */}
                        </div>
                    </div>
                    </div>
                </article>
            </section>
            <AtFooter/>
        </section>
    );
}

const HomeMobile = ()=>{
    return (
        <section className='home-layout-with-footer'>
            <section className='mobile-home-layout'>
                <AtHeader/>
                <article className='mobile-home-content'>
                    <div className='home-content-bg-container'>
                        <img src={bg} className='home-content-bg'/>
                    </div>
                    <div>
                        <div className='homeTitleAndDesc'>
                            <div className='homeTitle'>
                                <img src={titleSvg} alt="随鹿旅行 SOROUTE"/>    
                            </div>
                            <p className='homeDesc'>致力于应用AI打造完美<br/>用户体验的掌上自由行工具</p>
                        </div>
                        <div className='buttons'>
                            {/* <button className='homeButton ios' onClick={()=>{alert('敬请期待\nWaiting for the perfect self-guided tour experience in AI era.')}}>
                                <img src={appleSvg} alt="ios icon" className='homeAppleIcon'/>
                                <span className='homeButtonText'>
                                    ios版
                                </span>
                            </button>
                            <button className='homeButton android' onClick={()=>{alert('敬请期待\nWaiting for the perfect self-guided tour experience in AI era.')}}>
                                <img src={androidSvg} alt="android icon" className='homeAndroidIcon'/>
                                <span className='homeButtonText'>
                                    Android版
                                </span>
                            </button> */}
                            {/* <button className='home-button'
                                onClick={()=>{
                                    window.location.href = 'https://survey.soroute.cn/s/WGxqhd';
                                }}
                            >
                                <span className='home-button-text'>申请内测资格</span>
                            </button> */}
                            <a href='https://apps.apple.com/app/随鹿旅行/id6479046744?ppid=7d7acaa3-7fdd-46b4-9549-37c1965ba0e3' className='homeLink ios' >
                                <img src={appleSvg} alt="ios icon" className='homeAppleIcon'/>
                                <span className='homeButtonText'>
                                    ios版
                                </span>
                            </a>
                            <a href='https://a.app.qq.com/o/simple.jsp?pkgname=soroute.mobile' className='homeLink android'>
                                <img src={androidSvg} alt="android icon" className='homeAndroidIcon'/>
                                <span className='homeButtonText'>
                                    Android版
                                </span>
                            </a>
                        </div>
                    </div>
                </article>
            </section>
            <AtFooter/>
        </section>
    );
}